<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Controles</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Admin">Administrativo</router-link>
                  </li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Listas</li>
                </ol>
              </div>
            </div>
          </div>
          <!-- /.container-fluid -->
        </section>
        <!-- Main content -->
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11">
                    <div class="row">
                      <div class="col-md-3">
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="filtros.lista"
                          label="descripcion"
                          :options="listas"
                          placeholder="Listas"
                          @input="getItemsListas()"
                        ></v-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-1">
                    <div
                      class="btn-group float-right"
                      v-if="$store.getters.can('admin.listas.create')"
                    >
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#modal-form-items"
                        v-show="items != null"
                        @click="abrirModal('Crear', null)"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  id="users"
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>Numeracion</th>
                      <th>Codigo</th>
                      <th>Descripcion</th>
                      <th>Estado</th>
                      <th>Accion</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in items" :key="item.id">
                      <td>{{ item.numeracion }}</td>
                      <td>{{ item.cod_alterno }}</td>
                      <td>{{ item.descripcion }}</td>
                      <td>
                        <span
                          class="badge"
                          v-bind:class="[
                            item.estado == 1 ? 'badge-success' : 'badge-danger',
                          ]"
                        >
                          {{ item.estado == 1 ? "ACTIVO" : "INACTIVO" }}
                        </span>
                      </td>

                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            data-toggle="modal"
                            data-target="#modal-form-items"
                            @click="abrirModal('Editar', item.id)"
                            v-if="$store.getters.can('admin.listas.show')"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            @click="destroy(item.id)"
                            v-if="$store.getters.can('admin.listas.delete')"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="modal fade" id="modal-form-items">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">{{ modal.title }}</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="cod_alterno">Codigo Alterno</label>
              <select
                class="form-control form-control-sm"
                placeholder="Codigo Alterno"
                v-model="objLista.cod_alterno"
                v-if="filtros.lista && filtros.lista.id == 52"
              >
                <option value="">Seleccione...</option>
                <option value="SI">Si</option>
                <option value="NO">No</option>
              </select>
              <input
                v-else
                type="text"
                class="form-control form-control-sm"
                id="cod_alterno"
                placeholder="Codigo Alterno"
                v-model="objLista.cod_alterno"
              />
            </div>
            <div class="form-group">
              <label for="descripcion">Descripción</label>
              <input
                type="text"
                class="form-control form-control-sm"
                id="descripcion"
                placeholder="Descripcion"
                v-model="objLista.descripcion"
                :class="
                  $v.objLista.descripcion.$invalid ? 'is-invalid' : 'is-valid'
                "
              />
              <div class="error" v-if="!$v.objLista.descripcion.required">
                Ingrese una descripción
              </div>
            </div>
            <div class="form-group">
              <label for="estado">Estado</label>
              <select
                id="estado"
                class="form-control form-control-sm"
                v-model="objLista.estado"
                :class="$v.objLista.estado.$invalid ? 'is-invalid' : 'is-valid'"
              >
                <option value="">Seleccione...</option>
                <option
                  v-for="estado in arrListasForm.estados"
                  :key="estado.id"
                  :value="estado.id"
                >
                  {{ estado.descripcion }}
                </option>
              </select>
              <div class="error" v-if="!$v.objLista.estado.required">
                Seleccione un estado
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-show="!$v.objLista.$invalid"
              @click="save()"
              v-if="
                $store.getters.can('admin.listas.create') ||
                  $store.getters.can('admin.listas.show')
              "
            >
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import { required, minLength, between } from "vuelidate/lib/validators";

export default {
  name: "ControlIndex",
  components: {
    vSelect,
  },
  data() {
    return {
      filtros: {
        lista: null,
      },
      listas: [],
      items: null,
      objLista: {},
      arrListasForm: {
        estados: [],
      },
      modal: {
        title: "",
        accion: "",
      },
    };
  },
  validations: {
    objLista: {
      descripcion: {
        required,
      },
      estado: {
        required,
      },
    },
  },
  methods: {
    getListas() {
      axios.get("/api/admin/listas").then((response) => {
        this.listas = response.data;
      });
    },

    getItemsListas() {
      axios
        .get("/api/admin/listasItems/" + this.filtros.lista.id)
        .then((response) => {
          this.items = response.data;
        });
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.arrListasForm.estados = response.data;
      });
    },

    abrirModal(accion, idItem) {
      this.getEstados();
      this.modal.accion = accion;
      this.modal.title = accion + " Item";
      if (accion == "Editar") {
        this.show(idItem);
      } else {
        this.objLista = {};
        this.objLista.lista_id = this.filtros.lista.id;
      }
    },

    save() {
      if (!this.$v.objLista.$invalid) {
        let metodo = "";
        if (this.modal.accion == "Crear") {
          metodo = "POST";
        } else if (this.modal.accion == "Editar") {
          metodo = "PUT";
        }

        axios({
          method: metodo,
          url: "/api/admin/listasItems",
          data: this.objLista,
        })
          .then((response) => {
            this.$refs.closeModal.click();
            this.objLista = {};
            this.getItemsListas();
            this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vualeva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    show(idItem) {
      axios.get("/api/admin/listasItems/show/" + idItem).then((response) => {
        this.objLista = response.data;
      });
    },

    destroy(idItem) {
      this.$swal({
        title: "Esta seguro de eliminar este Item?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios.delete("/api/admin/listasItems/" + idItem).then((response) => {
            this.getItemsListas();
            this.$swal({
              icon: "success",
              title: "Se elimino el item exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
        }
      });
    },
  },
  mounted() {
    this.getListas();
  },
};
</script>
